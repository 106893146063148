<template>
  <v-app id="UTAP">
    <v-navigation-drawer :width="350" v-model="drawer" app clipped>
      <v-list large id="conversationList" v-show="conversations.length > 0" >
          <v-list-item :disabled="!conversationsInitialised" v-bind:style="{ 'background-color': index == selectedConversationIndex ? '#676767' :'' }" three-line @click="saveMessage(); conversationSelected(index)" v-bind:key="conversation.pan.digits"  v-for="(conversation,index) in conversations">  
            <div style="width: 60px;">
              <v-list-item-content class="messaging-conversation-icon tooltip">  
                <v-list-item-title> 
                  <v-icon size="20" v-if="conversation.read != null" v-bind:color="conversation.read ? 'white' : 'primary'"  >{{ conversation.read ? "drafts" : "mail" }}</v-icon>  
                </v-list-item-title>
                <v-list-item-subtitle class="tooltip"> 
                  <v-icon size="20" v-if="conversation.pan.appStatus != 1" style="color:red;">warning</v-icon>
                  <span class="tooltiptext" v-if="conversation.pan.appStatus != 1">This person does not have an active subscription on the Unify Messenger app. You can still send messages but they will not be able read/reply to them.</span>
                </v-list-item-subtitle> 
              </v-list-item-content> 
            </div>

            <v-list-item-content class="messaging-conversation-content">  
              <v-list-item-title> 
                {{ conversation.pan.forenames }} {{ conversation.pan.surname }}
              </v-list-item-title>
              <v-list-item-subtitle v-text="conversation.mostRecentMessage"></v-list-item-subtitle>
         
            </v-list-item-content>
            <v-list-item-action-text style="padding-bottom: 32px;">
              {{conversation.mostRecentTimestamp}}  
            </v-list-item-action-text>  
          </v-list-item>
      </v-list>

      <v-footer absolute>
        <v-col class="text-center">
            Messages sent using this service will be recorded and may be read by prison staff where necessary
        </v-col>
      </v-footer>
    </v-navigation-drawer> 

    <v-app-bar app clipped-left>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title v-if="tenantUsesFreeTexts">Free Messages: {{ this.freeTexts }} | Purchased Messages: {{ this.boughtTexts }}</v-toolbar-title>
      <v-toolbar-title v-else>Purchased Messages: {{ this.boughtTexts }}</v-toolbar-title>
      <v-spacer/> 

      <v-btn icon @click="openInfo()"><v-icon>info</v-icon> </v-btn>
      <v-btn icon @click="openPrivacy()"><v-icon >description</v-icon> </v-btn>
      <v-btn class="ml-5" color="primary"  @click="openBuyTextsDialog()">Buy Messages</v-btn>
    </v-app-bar> 

    <v-content>
      <v-container fluid>
        <div v-if="showInitiateConvo"> 
          <v-row align="center" justify="center" style="padding-top: 50px;"> 
            <v-btn @click="openInitiate()"> Click here to invite your contact to the messaging service</v-btn> 
          </v-row> 
        </div> 
        <div v-else id="conversation" ref="conversationContainer" v-on:scroll.passive='handleScroll'>
          <v-row v-bind:key="index"  v-for="(message,index) in displayedMessages" class="mb-6" no-gutters :justify="message.fromInside ? 'end' : 'start'">
            
            <div v-if="message.fromInside" :id="message.messageId" justify='end' style="max-width:60%">
              
               <div class="timestamp-fromMe" >{{ message.formattedTimestamp }}</div>
                <div class="message-fromMe">
                 {{message.text}}
                 <div style="height:10px; width:10px; float:right">
                 <v-icon v-if="message.read" small>mdi_done_all</v-icon>
                 <v-icon v-if="!message.read && message.status == $config.MessageStatus.SEND_SUCCESSFUL" small>mdi_done</v-icon>
                 </div>
                </div> 
               
            </div>
            <div v-else style="max-width:60%">
              <span class="timestamp-fromYou">{{ message.formattedTimestamp }}</span>
              <div class="message-fromYou">{{message.text}}</div>
            </div>
            <v-progress-circular v-if="message.status == $config.MessageStatus.SEND_PENDING && message.fromInside" class="message-delivering-indicator" size="25" indeterminate color="white"></v-progress-circular>
            <v-btn v-if="message.status == $config.MessageStatus.SEND_FAILED && message.fromInside" @click="resendMessage(message.messageId)" class="message-error-indicator" icon color="red">
              <v-icon>error</v-icon>
            </v-btn> 
          </v-row>
          
        </div> 
        <v-btn v-if="!isConversationAtBottom" style="margin: 0px 0px 125px 0px" @click="scrollConversationToEnd()" color="dark grey" dark small absolute bottom right fab>
         <v-icon color="white">keyboard_arrow_down</v-icon>
       </v-btn>
       <div id="messageField" v-if="!showInitiateConvo">     
        <v-text-field id="messageTextField" label="Message" solo rounded counter :maxlength="$config.ClientDetails.MESSAGE_MAX_CHARS" autocomplete="off" v-model="message" append-icon="mdi-send" @keyup.enter="sendClicked()" @click:append="sendClicked()"></v-text-field>
      </div>
      
    </v-container>
  </v-content>
  <ConnectionErrorDialog ref="connectionErrorDialog" v-on:retryClicked="retryConnection"></ConnectionErrorDialog>
  <confirmationDialog ref="confirmationDialog"></confirmationDialog>
  <buyTextsDialog ref="buyTextsDialog"></buyTextsDialog> 
  <PDFDialog ref="PDFDialog"></PDFDialog> 
  <InfoDialog ref="InfoDialog"></InfoDialog> 
  <initiateDialog ref="initiateDialog"></initiateDialog> 
  <suspendedDialog ref="suspendedDialog"></suspendedDialog>
  <v-snackbar style="margin-left:100px"  v-model="showErrorNotification" top='top' :timeout='0'> Unable to establish a connection to server 
    <v-btn color="primary" text @click="showErrorNotification = false">Close</v-btn>
  </v-snackbar>
  <v-snackbar style="margin-left:100px" v-model="showNoCreditNotification" top='top' :timeout='0'> Insufficient Credit
    <v-btn color="primary" text @click="showNoCreditNotification = false">Close</v-btn>
  </v-snackbar>
  <v-snackbar style="margin-left:100px" v-model="showPINOnCallNotification" top='top' :timeout='0'> Your PIN is being used on a call
    <v-btn color="primary" text @click="showPINOnCallNotification = false">Close</v-btn>
  </v-snackbar> 
</v-app>
</template>

<script>
  import confirmationDialog from '../Base/components/ConfirmationDialog';
  import MessageHub  from '../Signalling/MessageHub.js'
  import buyTextsDialog from '../Dialogs/BuyTextsDialog'; 
  import PDFDialog from '../Dialogs/PDFDialog';  
  import InfoDialog from '../Dialogs/InfoDialog'; 
  import initiateDialog from '../Dialogs/InitiateDialog'; 
  import suspendedDialog from '../Dialogs/SuspendedDialog';
  import ConnectionErrorDialog from "../Dialogs/ConnectionErrorDialog.vue" 
  
  export default {
    name: "messaging",
    components:{
      confirmationDialog,
      suspendedDialog,
      initiateDialog,
      buyTextsDialog, 
      ConnectionErrorDialog,
      PDFDialog,
      InfoDialog
    },

    data: () => ({
      drawer: null,
      pans: [],  
      messageHubConnection: null, 
      conversationsInitialised: false, 
      conversations: [], 
      showErrorNotification: false,
      showNoCreditNotification: false,
      showPINOnCallNotification: false, 
      isMessageSending: false,
      displayedMessages: [],
      currentUserPIN: null,
      message: "",
      timestamp: null,
      isConversationAtBottom: true,
      isConversationSelected: false,
      openId: null,
      sentenceNumber: null,
      credit: null,
      freeTexts: null, 
      bFreeTexts: false, 
      suspended: false,
      suspensionReason: "",
      selectedConversationIndex: null,
      selectedConversationPAN: null,
      lastSelectedConversationIndex: null, 
      renderMessageList: true, 
      tenantUsesFreeTexts: false,
      boughtTexts: null,
      PricePerMessage: null, 
      bodiesPerFetch: 150,
      showInitiateConvo: false, 
      retryingConnection: false
    }),

    props:["userCreds"],

    methods: {
      openInfo() {  
        this.$refs.InfoDialog.open().then((confirm) => { }) 
      },
      openPrivacy() {  
        this.$refs.PDFDialog.open().then((confirm) => { }) 
      },
      openInitiate() {   
        this.sendMessage("You have a new message. To view it download the app").then(() => { 
            this.$refs.initiateDialog.open() 
            self.updateBalance()  
          })
          .catch(error => { 
            self.isMessageSending = false
          })
      },
      sendClicked(){ 
        const self = this
        if(!this.isMessageSending && this.message != "" && this.selectedConversationIndex != null && this.message.length <= self.$config.ClientDetails.MESSAGE_MAX_CHARS && !this.retryingConnection){
          this.isMessageSending = true

          this.sendMessage(this.message).then((body) => { 
            self.message = ""   
            self.isMessageSending = false 

            const formattedTimestamp = new Date(body.timestamp).toLocaleString(self.$config.timedate_format);
            body.formattedTimestamp = formattedTimestamp

            self.conversations[self.selectedConversationIndex].bodies.push(body)
            self.conversations[self.selectedConversationIndex].mostRecentTimestamp = formattedTimestamp
            self.conversations[self.selectedConversationIndex].mostRecentMessage = body.text
            self.conversations[self.selectedConversationIndex].read = true
            
            self.displayedMessages = self.conversations[self.selectedConversationIndex].bodies

            self.moveConversation(self.selectedConversationIndex,0)
            self.selectedConversationIndex = 0  

            self.updateBalance()
            self.forceConversationRender()
            
          })
          .catch(error => { 
            self.isMessageSending = false
          })
        }
      },

      messageReceived(message){
       
        const conversationIndex = this.conversations.findIndex(x => x.pan.digits == message.pan)
        let conversation = this.conversations[conversationIndex]

        this.moveConversation(conversationIndex,0)

        const formattedTimestamp = new Date(message.body.timestamp).toLocaleString(this.$config.timedate_format);
        conversation.bodies.push(message.body)
        conversation.mostRecentTimestamp = formattedTimestamp
        conversation.mostRecentMessage = message.body.text
        conversation.read = false
        
        if(this.selectedConversationIndex == conversationIndex){
          this.displayedMessages = conversation.bodies
          this.markRead()
          this.forceConversationRender()
          this.selectedConversationIndex = 0

        }
      },
    
      focusOnTextField() {
        document.getElementById("messageTextField").focus();
        // if (this.selectedConversationIndex != null) {
        //   this.message = this.conversations[this.selectedConversationIndex].savedMsg;
        // }
      },
      saveMessage() {
        if (this.selectedConversationIndex != null) {
          this.conversations[this.selectedConversationIndex].savedMsg = this.message;
        }
      }, 
      handleScroll(event){
        var container = document.querySelector('#conversation');
        var scrollPos = container.scrollTop;
        var scrollHeight = container.scrollHeight;
        var containerHeight = this.$refs.conversationContainer.clientHeight;
        const scrollLimit = 250
        
        if(scrollPos < (scrollHeight - containerHeight) - scrollLimit){
          this.isConversationAtBottom = false
        }
        else this.isConversationAtBottom = true
      },
      markRead(){
        if(this.selectedConversationIndex != null){
          var selectedConversation = this.conversations[this.selectedConversationIndex]
          
          if(!selectedConversation.read){
            const uri = 'messages/pin/' + this.currentUserPIN + '/pan/' + this.conversations[this.selectedConversationIndex].pan.digits + '/readInternal';
            this.ApiService.post(uri,null)
          }

          this.conversations[this.selectedConversationIndex].read = true;
          
        }
      },
      fetchOlderMessages(){
        if(this.conversations.length > 0 && this.conversations[this.selectedConversationIndex].bodies.length > 0){
          let oldestMessageTimestamp = this.conversations[this.selectedConversationIndex].bodies[0].timestamp;
          const uri = 'pins/' + this.currentUserPIN + '/pan/' + this.conversations[this.selectedConversationIndex].pan.digits + "/client?BeforeDate=" + oldestMessageTimestamp;
          var container = this.$el.querySelector("#conversation");
          var initialHeight = container.scrollHeight;

          this.ApiService.get(uri).then((response) => {
            var fetchedMessages = response.data;                  
            for(var messageIndex = fetchedMessages.bodies.length-1; messageIndex >= 0; messageIndex--){
              if(fetchedMessages.bodies[messageIndex].fromInside) fetchedMessages.bodies[messageIndex].status = this.$config.MessageStatus.SEND_SUCCESSFUL
              else fetchedMessages.bodies[messageIndex].status = this.$config.MessageStatus.SEND_NOT_SENT;
          }
          if(fetchedMessages.bodies.length > 0){
            var temp = fetchedMessages.bodies.concat(this.conversations[this.selectedConversationIndex].bodies);
            this.conversations[this.selectedConversationIndex].bodies = temp
            container.style.visibility = "hidden";
            this.updateConversationMessages()

              setTimeout(() => { // setTimeout to put this into event queue
                // executed after render
                container.style.visibility = "visible";
                let totalOffSet = container.scrollHeight - initialHeight;
                container.scrollTop = totalOffSet
              }, 0)
            }
          });       
      }
    },

    updateMessageStatus(pan,messageID,statusCode){
  
      var convIndex = this.conversations.findIndex(c => c.pan.digits == pan);
      var bodyIndex = this.conversations[convIndex].bodies.findIndex(body => body.messageId == messageID)
      
      if(bodyIndex != -1){
        this.conversations[convIndex].bodies[bodyIndex].status = statusCode;
      }
    },
    moveConversation(from, to) {    
      var elementToMove = this.conversations[from]
      this.conversations.splice(from,1);
      this.conversations.splice(to,0,elementToMove)
    },
    sortConversationsByLatest(conversations) {
      if(conversations.length > 1){
         conversations.sort(function(a,b){
          var timeA = a.bodies.length > 0 ? a.bodies[a.bodies.length-1].timestamp : null;
          var timeB = b.bodies.length > 0 ? b.bodies[b.bodies.length-1].timestamp : null;

          if(timeA < timeB || (timeA == null && timeB != null)) return 1;
          else if(timeA > timeB || (timeB == null && timeA != null)) return -1;
          else if(timeA == timeB || (timeB == null && timeA == null)) return 0;

        });
      }
      return conversations
    },

    sortConversations(conversations) {
      if(conversations.length > 1){
         conversations.sort(function(a,b){
          var timeA = a.timestamp
          var timeB = b.timestamp

          if(timeA < timeB || (timeA == null && timeB != null)) return 1;
          else if(timeA > timeB || (timeB == null && timeA != null)) return -1;
          else if(timeA == timeB || (timeB == null && timeA == null)) return 0;

        });
      }
      return conversations
    },

    updateBalance(){
      const self = this
      this.getAccountCredit().then(balance => {
        self.credit = balance.credit;
        self.freeTexts = balance.freeTexts;
        self.boughtTexts = balance.boughtMessages
      })
    },

    conversationSelected(index){
      if(index != null ){
        this.showInitiateConvo = false
        this.selectedConversationIndex = index
        
        this.isConversationSelected = true
        var appStatus = this.conversations[this.selectedConversationIndex].pan.appStatus
        var messages = this.conversations[this.selectedConversationIndex].bodies
        var contactMessages = messages.filter(item => item.fromInside === false) 
        if(appStatus != 1 && contactMessages.length == 0)
        {  
          this.showInitiateConvo = true
        }
        else
        { 
          this.displayedMessages = this.conversations[this.selectedConversationIndex].bodies

          this.markRead()
          //this.focusOnTextField()

          this.$nextTick(() => {
            this.scrollConversationToEnd()
          }) 
        } 
      }
    },

      logout(){
        const dialogTitle = "Logging out"
        const dialogMessage = "Are you sure you want to log out?"
        this.$refs.confirmationDialog.open(dialogTitle,dialogMessage).then((confirm) => {
          if(confirm){
            this.mgr.signoutRedirect();
            this.mgr.removeUser()
            this.$session.destroy()
          }
        })
      }, 

     forceConversationRender(){
        this.renderMessageList = false
        var self = this
        this.$nextTick().then(() => {
          this.renderMessageList = true

          self.$nextTick().then(() => {
            self.scrollConversationToEnd()
          })
        })
        
     },

      scrollConversationToEnd() {  
        var container = this.$el.querySelector("#conversation");
        container.scrollTop = container.scrollHeight;   
      },
      setUpObserver() {
        var conversationElement = document.querySelector('#conversation');
        var observer = new MutationObserver(this.scrollConversationToEnd);
        var config = {childList: true};
        observer.observe(conversationElement, config);
      },
      refreshPage() {
        location.reload();
      },

      openBuyTextsDialog() {
        this.getAccountCredit().then(credit => {
          this.$refs.buyTextsDialog.open(this.sentenceNumber, this.PricePerMessage, credit.credit).then((confirm) => {
              if(confirm){
                this.updateBalance()
              }
              else{
                this.showNoCreditNotification = true
              }
          })
        }) 
      },



      //------------- API CALLS ------------------
      getConversations(){
          const self = this
          const conversationUri = 'messages/pin/' + this.currentUserPIN + '/conversations?MessagesPerConversation=' + this.bodiesPerFetch;
          var conversations = []
          return new Promise(function(resolve,reject){
            self.ApiService.get(conversationUri).then(response => {
              conversations = response.data
        
              conversations.forEach(conversation => {
                self.suspended = conversation.pin.suspended
                self.suspensionReason = conversation.pin.suspensionReason
                var conversation_bodies = conversation.bodies
  
                conversation_bodies.forEach(body => {
                  const formattedTimestamp = new Date(body.timestamp).toLocaleString(self.$config.timedate_format);
                  body.formattedTimestamp = formattedTimestamp
                  if(body.status == self.$config.MessageStatus.SEND_PENDING) body.status = self.$config.MessageStatus.SEND_FAILED;
                })

                if(conversation_bodies.length > 0){
                  conversation.mostRecentTimestamp = conversation_bodies[conversation_bodies.length - 1].formattedTimestamp
                  conversation.mostRecentMessage = conversation_bodies[conversation_bodies.length - 1].text
                  conversation.read = conversation_bodies[conversation_bodies.length - 1].fromInside ? true : conversation_bodies[conversation_bodies.length - 1].read
                }
                else{
                  conversation.mostRecentMessage = "No messages available"
                  conversation.read = true    
                }
                
              })

              resolve(conversations)

            })
            .catch(error => {
              reject(error)
            })
          })
        }, 


        sendMessage(message){
        const self = this
        const messageTrimmed = message.trim()

        var body = {fromInside: true, text: messageTrimmed}
        var data = {PIN: self.currentUserPIN, PAN: self.conversations[self.selectedConversationIndex].pan.digits, Body: body}

        return new Promise((resolve, reject) => {
          if(messageTrimmed != null){
            var body = { fromInside: true, text: messageTrimmed}
            var data = { "PIN": self.currentUserPIN, "PAN": self.conversations[self.selectedConversationIndex].pan.digits, "Body": body};

            this.ApiService.post('messages',data).then(response => {
              if(response.data.noCredit){
                self.showNoCreditNotification = true
                reject("No credit")
              } 
              else if(response.data.pinOnCall) {
                self.showPINOnCallNotification = true
                reject("On call")
              }
              else{
                
                const timestampReadable = new Date(response.data.timestamp).toLocaleString(self.$config.timedate_format)
                const messageBody = {
                  fromInside: true,
                  text: messageTrimmed,
                  timestamp: response.data.timestamp,
                  formattedTimestamp: timestampReadable,
                  status: self.$config.MessageStatus.SEND_SUCCESSFUL, 
                  messageId: response.data.messageId
                }
                resolve(messageBody)
              }
            })
            .catch(error => {
              reject(error)
            })

          }
        })
        
      },

      getAccountCredit(){
        const self = this
        return new Promise(function(resolve,reject){
          self.ApiService.get("PINs/" + self.currentUserPIN + "/credit").then(response => {
            const balance = {credit: response.data.balance.toFixed(2), freeTexts: response.data.freeTexts, boughtMessages: response.data.boughtMessages}
            resolve(balance)
          })
          .catch(error => {
            reject(error)
          })
        })
      },

      
      connectMessageHub(){
        const socketUrl = this.$config.APIServerDetails.SocketPath
        this.messageHubConnection = new MessageHub(socketUrl, this.openId.access_token)

        this.messageHubConnection.start().then(() => {
          this.messageHubConnection.connection.onclose(() => this.retryConnection())

          this.messageHubConnection.connection.on("SendMessage", (message) => {
            this.messageReceived(message)
          })
          this.retryingConnection = false
        })
        .catch(error => {
          this.retryingConnection = false
        })
      },
      retryConnection(){
        if(!this.retryingConnection){
          this.retryingConnection = true

            this.messageHubConnection.retryConnection().then(() => {
              this.getConversations()
              this.$refs.connectionErrorDialog.close()
            }).catch(() => {
              this.$refs.connectionErrorDialog.open()
            })
            .finally(() => {
              this.retryingConnection = false
            })
        }
      }
    },

    
  created () {
    this.ApiService.setDefaultBaseURL(this.$config.APIServerDetails.Url)

		if(this.userCreds != null){
      this.openId = this.userCreds;
      this.currentUserPIN = this.userCreds.profile.PIN ? this.userCreds.profile.PIN : this.userCreds.profile.pin
      this.sentenceNumber = this.userCreds.profile.SentenceNumber ? this.userCreds.profile.SentenceNumber : this.userCreds.profile.sentence
      if(this.$config.useLegacyAuth) {
        this.bFreeTexts = this.$config.TenantDetails[this.userCreds.profile.Tenant].FreeTexts
         this.PricePerMessage = this.$config.TenantDetails[this.userCreds.profile.Tenant].PricePerMessage
      }
      else {
        this.bFreeTexts = this.$config.TenantDetails[this.userCreds.profile.site].FreeTexts
        this.PricePerMessage = this.$config.TenantDetails[this.userCreds.profile.site].PricePerMessage
      }
    }
    if(this.$config.useLegacyAuth) {
      this.tenantUsesFreeTexts = this.$config.TenantDetails[this.userCreds.profile.Tenant].FreeTexts 
      this.PricePerMessage = this.$config.TenantDetails[this.userCreds.profile.Tenant].PricePerMessage
    }
    else {
        this.tenantUsesFreeTexts = this.$config.TenantDetails[this.userCreds.profile.site].FreeTexts
        this.PricePerMessage = this.$config.TenantDetails[this.userCreds.profile.site].PricePerMessage
    }

      this.getConversations().then((convs) => { 

      this.updateBalance()

      if(this.suspended)
      { 
        this.$refs.suspendedDialog.open(this.suspensionReason)
      }
      else
      {   
        this.conversations = convs
        this.conversationsInitialised = true
        this.connectMessageHub()
      }
      })
  },
  
  mounted () {
    this.setUpObserver();
    
   // this.updateChats(true) 
  },

  beforeDestroy(){

  }
}
</script>
