<template>
	<v-dialog persistent v-model="isVisible" :max-width="styleSettings.width" :style="{ z: styleSettings.z }" @keydown.esc="cancel">
		<v-card>
			<v-toolbar dark color="#1b526d" dense flat>
				<v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
			</v-toolbar>
			<v-card-text v-show="message" class="pa-4">{{ message }}</v-card-text>
			<v-card-actions class="pt-0">
				<v-spacer></v-spacer>
				<v-btn color="#2d7da5" text @click.native="confirm"><b>Yes</b></v-btn>
				<v-btn color="grey" text @click.native="cancel">No</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	data: () => ({
		isVisible: false,
		resolve: null,
		message: null,
		title: null,
		styleSettings: {
		color: 'primary',
		width: 350,
		z: 200
		}
	}),
	methods: {
		open(title, message, styleSettings) {
			this.isVisible = true
			this.title = title
			this.message = message
			this.styleSettings = Object.assign(this.styleSettings, styleSettings == null ? this.styleSettings : styleSettings)
			return new Promise((resolve) => {
				this.resolve = resolve
			})
		},
		confirm() {
			this.resolve(true)
			this.isVisible = false
		},
		cancel() {
			this.resolve(false)
			this.isVisible = false
		},
		reset() {
			this.resolve = null,
			this.message = null,
			this.title = null,
			this.styleSettings = {color: 'primary', width: 400, z:200}
		}
	}
}
</script>