import { signalR, HubConnectionBuilder, LogLevel, HttpTransportType } from "@aspnet/signalr"
import { setInterval } from "core-js"

export default class MessageHub{
    constructor(url, token){
        this.connection = new HubConnectionBuilder()
            .withUrl(url, {accessTokenFactory: () => token} )
            //.configureLogging(LogLevel.Warning)
            .build()
        
        this.retryTimerMS = 5000
        this.maxReconnectAttempts = 3
        this.retryCount = 0
        this.connectionId = null
        this.retryingConnection = false
        
    }

    getConnectionId(){
        this.connection.invoke('getConnectionId').then((data => {
            this.connectionId = data
        }))
    }

    retryConnection(){
        this.retryCount = 0
        this.retryingConnection = true

        const self = this
        return new Promise((resolve, reject) => {

            const interval = setInterval(function() {
                    self.start()
                    .then(() => {
                        console.log("Reconnected socket successfully!")
                        self.retryingConnection = false
                        clearInterval(interval)
                        resolve()
                    })
                    .catch(error => {
                        self.retryCount++
                        if(self.retryCount >= self.maxReconnectAttempts){
                            self.retryingConnection = false
                            clearInterval(interval)
                            reject()
                        }
                        console.warn("Attempting to reconnect socket...")
                    })
            }, this.retryTimerMS, this.maxReconnectAttempts)

        })
    }

    start () {
        return new Promise((resolve,reject) => {
            this.connection.start()
            .then(() => { 
                resolve()
            })
            .catch(() => {
                reject()
            })
        })
    }

    close(){
        return new Promise((resolve,reject) => {
            this.connection.stop().then(() => {
                resolve()
            })
            .catch(error => {
                reject(error)
            })
        })
    }
}

