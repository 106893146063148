<template>
	<v-dialog persistent v-model="isVisible" :max-width="styleSettings.width" :style="{ z: styleSettings.z }" @keydown.esc="cancel">
		<v-card>
			<v-toolbar dark color="#1b526d" dense flat>
				<v-toolbar-title class="white--text">Buy Messages</v-toolbar-title>
			</v-toolbar>

			<v-container class="pl-4 pr-1">
  				<v-row no-gutters>
    				<v-col md="2" sm="2" class="mt-3">
						<v-btn fab dark small @click="decrement()" >
      						<v-icon dark>mdi-minus</v-icon>
   						</v-btn>
   					</v-col>
    				<v-col md="8" class="pr-3">
						<v-row>
							<v-text-field persistent-hint autofocus style="padding-left: 20px; padding-right:20px; white-space: pre" ref="numberSelector" 
							readonly hide-spin-buttons v-model="numberOfMessagesToBuy" type="number" label="Number of messages:" 
							:hint="computeHintText"></v-text-field>
						</v-row>
	
			
   					</v-col>
					<v-col md="2" sm="2" class="mt-3">
						<v-btn fab dark small @click="increment()" >
      						<v-icon dark> mdi-plus</v-icon>
   						</v-btn>
   					</v-col>
  				</v-row> 
			</v-container>
			
			<v-card-actions class="pt-0">
				<v-spacer></v-spacer>
				<v-btn color="#2d7da5" text @click.native="confirm"><b>Buy</b></v-btn>
				<v-btn color="grey" text @click.native="cancel">Cancel</v-btn>
			</v-card-actions>
		</v-card>

		<v-dialog persistent v-model="noCreditDialog" :max-width="styleSettings.width" :style="{ z: styleSettings.z }">
			<v-card>
				<v-toolbar dark color="#1b526d" dense flat>
					<v-toolbar-title class="white--text">Error</v-toolbar-title>
				</v-toolbar>
				<v-card-text class="pa-4">Insufficient PIN credit</v-card-text>
				<v-card-actions class="pt-0">
					<v-btn color="#2d7da5" text @click="noCreditDialog = false"><b>Ok</b></v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-dialog>
</template>

<script>
export default {
	data: () => ({
		isVisible: false,
		resolve: null,
		reject: null,
		incrementBy: 5,
		numberOfMessagesToBuy: 50,
		pricePerMessage: 0,
		maximumMessages: 500,
		minimumMessages: 1,
		noCreditDialog: false,
		totalCredit: null,
		sentenceNumber: null,
		styleSettings: {
		color: 'primary',
		width: 350,
		z: 200
		}
	}),

	computed:{
		computeHintText(){
			return 'Total: £' + this.calculatePrice() + '\nBalance: ' + "£" + parseFloat(this.totalCredit)
		},
	},

	methods: {

		open(sentenceNumber, price, totalCredit, styleSettings) {
			this.isVisible = true
			this.sentenceNumber = sentenceNumber
			this.numberOfMessagesToBuy = 50
			this.pricePerMessage = price
			this.totalCredit = totalCredit
			this.styleSettings = Object.assign(this.styleSettings, styleSettings == null ? this.styleSettings : styleSettings)
			return new Promise((resolve, reject) => {
				this.resolve = resolve
			})
		},
		increment () {
			if(this.numberOfMessagesToBuy < this.maximumMessages){
				if(this.numberOfMessagesToBuy == 1){
					this.numberOfMessagesToBuy = 5
				}
				else{
					this.numberOfMessagesToBuy = parseInt(this.numberOfMessagesToBuy,10) + this.incrementBy
				}
				
				this.$refs.numberSelector.focus()
			}
      		
    	},
		decrement () {
			if(this.numberOfMessagesToBuy > this.minimumMessages){
				if(this.numberOfMessagesToBuy == 5){
					this.numberOfMessagesToBuy = 1
				}
				else{
					this.numberOfMessagesToBuy = parseInt(this.numberOfMessagesToBuy,10) - this.incrementBy
				}
				
				this.$refs.numberSelector.focus()
			} 
		},
		calculatePrice(){
			if(this.pricePerMessage == null ) return 0
			const cost =  this.numberOfMessagesToBuy * this.pricePerMessage
			return cost.toFixed(2)
		},
		confirm() {
			if(this.numberOfMessagesToBuy >= this.minimumMessages && this.numberOfMessagesToBuy <= this.maximumMessages){
				const uri = 'pins/' + this.sentenceNumber + '/buyTexts/' + this.numberOfMessagesToBuy;
				this.ApiService.post(uri).then((response) => {
				const hasSufficientCredit = response.data

				this.resolve(hasSufficientCredit)
				this.isVisible = false
			})
			}
			
		},
		cancel() {
			this.numberOfMessagesToBuy = this.minimumMessages
			this.isVisible = false
		},
		reset() {
			this.resolve = null,
			this.styleSettings = {color: 'primary', width: 400, z:200}
		}
	}
}
</script>

<style scoped>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}
</style>
