<template>
	<v-dialog persistent v-model="isVisible" :max-width="styleSettings.width" :style="{ z: styleSettings.z }">
		<v-card>
			<v-toolbar dark color="#1b526d" dense flat>
				<v-toolbar-title class="white--text">Connection lost</v-toolbar-title>
			</v-toolbar>

			<v-card-text class="pt-5">Please retry. If the problem persists try again later</v-card-text>
            <v-card-actions>
                <v-spacer/>
                
                <v-btn :disabled="disableRetry" class="mr-2" color="primary" @click="retryClicked">Retry</v-btn>
                <v-progress-circular v-if="disableRetry" size="25" indeterminate></v-progress-circular>
            </v-card-actions>
		</v-card> 
	</v-dialog>
</template>

<script>
export default {
	data: () => ({
        disableRetry: false,
		isVisible: false, 
		styleSettings: {
		color: 'primary',
		width: 400,
		z: 200
		}
	}),

	methods: { 
        retryClicked(){
            this.$emit('retryClicked')
            this.disableRetry = true
            setTimeout(() => this.disableRetry = false, 20000)
        },
		open(styleSettings) {
            this.disableRetry = false
			this.isVisible = true 
			this.styleSettings = Object.assign(this.styleSettings, styleSettings == null ? this.styleSettings : styleSettings) 
		}, 
        close(){
            this.isVisible = false
        },
		reset() { 
			this.styleSettings = {color: 'primary', width: 400, z:200}
		}
	}
}
</script>  