<template>
	<v-dialog persistent v-model="isVisible" :max-width="styleSettings.width" :style="{ z: styleSettings.z }">
		<v-card>
			<v-toolbar dark color="#1b526d" dense flat>
				<v-spacer />
				<v-toolbar-title class="white--text">Your invitation has been sent! </v-toolbar-title>
				<v-spacer />
			</v-toolbar> 

			<v-container class="pl-4 pr-1">
  				<v-row align="center" justify="center" no-gutters>
  					<div>Once your contact has a valid subscription you will be able to continue the conversation</div>
  				</v-row>  
			</v-container> 

			<v-card-actions class="pt-0">
				<v-spacer></v-spacer> 
				<v-btn color="grey" text @click.native="cancel">Cancel</v-btn>
			</v-card-actions>
		</v-card> 
	</v-dialog>
</template>

<script>
export default {
	data: () => ({
		isVisible: false,  
		styleSettings: {
		color: 'primary',
		width: 400,
		z: 200
		}
	}),

	methods: { 
		open() { 
			this.isVisible = true 
			this.styleSettings = Object.assign(this.styleSettings) 
		}, 
		reset() { 
			this.styleSettings = {color: 'primary', width: 400, z:200}
		},
		cancel() { 
			this.isVisible = false
		},
	}
}
</script>  