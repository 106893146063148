<template>
<v-dialog v-model="isVisible" fullscreen>
    <v-card tile>
      <v-card-title class="py-2 px-2"> 
        <v-btn small color="wihte" icon dark @click="reset()">
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </v-card-title>
     <div id="pdfContainer"><vue-pdf-embed :source="pdf" disableAnnotationLayer disableTextLayer /></div>
    </v-card>
</v-dialog>

</template>

<script> 
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";  
import Info from '../assets/info.txt'

export default {
  data: () => ({
    isVisible: false,
    resolve: null,  
    pdf: null
  }), 
  components: { 
    VuePdfEmbed
  }, 
  methods: { 
    open() {
      this.isVisible = true
      this.pdf = "data:application/pdf;base64," + Info 
      return new Promise((resolve) => {
        this.resolve = resolve
      })
    },
    reset() {
      this.resolve = null
      this.pdf = null 
      this.isVisible = false
    }
  }
}
</script> 