<template>
	<v-dialog persistent v-model="isVisible" :max-width="styleSettings.width" :style="{ z: styleSettings.z }">
		<v-card>
			<v-toolbar dark color="#1b526d" dense flat>
				<v-spacer />
				<v-toolbar-title class="white--text">Your Account Is Suspended</v-toolbar-title>
				<v-spacer />
			</v-toolbar>

			<v-container class="pl-4 pr-1">
  				<v-row align="center" justify="center" no-gutters>
  					<div> Suspention Reason:</div>
  				</v-row> 
  				<v-row align="center" justify="center" no-gutters>
  					<div style="text-align: center;">{{reason}}</div>
  				</v-row> 
			</v-container> 
		</v-card> 
	</v-dialog>
</template>

<script>
export default {
	data: () => ({
		isVisible: false, 
		reason: "",
		styleSettings: {
		color: 'primary',
		width: 400,
		z: 200
		}
	}),

	methods: { 
		open(reason, styleSettings) {
			this.reason = reason
			this.isVisible = true 
			this.styleSettings = Object.assign(this.styleSettings, styleSettings == null ? this.styleSettings : styleSettings) 
		}, 
		reset() { 
			this.styleSettings = {color: 'primary', width: 400, z:200}
		}
	}
}
</script>  